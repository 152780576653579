import { AffichageVilles, AllVilles, VillesSearch } from "../../types/api/RetourApiVilles";
import { FetchGH }                                  from "../../components/generality/FetchGH";


export class VilleApi {
    
    private fetchGH: FetchGH;
    
    constructor(mapId?: number) {
        this.fetchGH = new FetchGH("villes", mapId ?? 0);
    }
    
    public all(): Promise<AllVilles> {
        return this.fetchGH.get("/all");
    }
    
    public mapId(mapid: number) {
        return this.fetchGH.get("/" + mapid);
    }
    
    public search(data: { nom: string | null, type: string[] | null, etat: string[] | null, saison: number | null, lang: string[] | null }): Promise<VillesSearch> {
        return this.fetchGH.post({ path: "/search", data: data });
    }
    
    public main(): Promise<AffichageVilles> {
        return this.fetchGH.get("/");
    }
    
}