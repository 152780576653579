import { useTranslation } from "react-i18next";
import SvgIcone           from "../../../components/generality/SvgIcone";
import React              from "react";
import ScrollLink         from "../../../components/utils/ScrollLink";

export default function ItemAssemblageList({ id, icon, nom, complement, classComplement, classProps, classAdd, inEncyclo = true }: {
    id: number, icon: string, nom: string, complement: string, classComplement: string, classProps: string, classAdd?: string, inEncyclo?: boolean
}) {
    const { t } = useTranslation();
    return <span className={classProps + " infoBulle"}>
        {inEncyclo && <ScrollLink targetId={`items_${id}`}>
            <span className={"itemAssemblage" + classAdd}>
                <SvgIcone icone={icon} />
            </span>
        </ScrollLink>}
        {!inEncyclo && <span className={"itemAssemblage" + classAdd}>
                <SvgIcone icone={icon} />
            </span>}
        {complement !== "" && <span className={classComplement}>{complement}</span>}
        <span className="info">{t(nom, { ns: "items" })} (id:{id})</span>
    </span>;
}