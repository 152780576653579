import React                    from "react";
import ColorPicker              from "../../components/generality/ColorPickerGH";
import { ColorPickerItemProps } from "../../types/components/OptionsPerso/OptionsPersoType";


export default function ColorPickerItem({ label, color, onChangeColor, tooltip }: ColorPickerItemProps) {
    
    return <div className={"gestion_option_couleur_carte"}>
        {(tooltip) ? <>
            <span className={"option_specifique"}>{label}</span>
            <span className={"option_specifique infoBulle"}>
                <i className="fa fa-circle-info"></i>
                <span className="option_specifique infoHelpPerso">{tooltip}</span>
            </span>
        </> : <span>{label}</span>}
        <ColorPicker color={color} onChangeColor={onChangeColor} />
    </div>;
}
