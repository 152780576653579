import React, { useEffect, useState } from "react";
import SvgIcone                       from "./SvgIcone";
import avatar_vide                    from "../../../img/avatar_vide.png";
import { ItemPrototypeDTO }           from "../../types/models/itemPrototype.dto";
import { useTranslation }             from "react-i18next";

export function Avatar(props: {
    url: string | null;
    classAvatar?: string | null;
}) {
    const [avatar, setAvatar] = useState(props.url);
    
    useEffect(() => {
        setAvatar(props.url);
    }, [props.url]);
    
    const handleError = () => {
        setAvatar(null);
    };
    
    if (avatar === null) {
        return (
            <img
                src={avatar_vide}
                className={props.classAvatar ? props.classAvatar : null}
                alt="avatar"
                onError={handleError}
            ></img>
        );
    } else {
        return (
            <img
                src={avatar}
                className={props.classAvatar ? props.classAvatar : null}
                alt="avatar"
                onError={handleError}
            ></img>
        );
    }
}

export function AffNbrIcon(props: {
    item: ItemPrototypeDTO;
    broken: boolean;
    nbr: number;
    onDecrementItem?: (item: ItemPrototypeDTO, broken: boolean) => void;
    classSpecifique?: string
}) {
    const { t } = useTranslation();
    let typeName = "";
    let classImage;
    
    const item = props.item;
    const broken = props.broken;
    const nbr = props.nbr;
    
    let classSpecifique = "videItem";
    if (props.classSpecifique !== undefined) {
        classSpecifique = props.classSpecifique;
    }
    
    if (item?.expedition) {
        classImage = "videImg5";
        typeName = t("(expédition)", { ns: "items" });
    } else {
        if (item.type_objet?.id === 3) {
            classImage = "videImg4";
            typeName = t("(Marqueur)", { ns: "items" });
        } else {
            if (item.id >= 2000 && item.id < 3000) {
                classImage = "videImg3";
                typeName = t("empoisonné(e)", { ns: "items" });
            } else {
                if (broken) {
                    classImage = "videImg2";
                    typeName = t("cassé(e)", { ns: "items" });
                } else {
                    classImage = "videImg";
                }
            }
        }
    }
    
    
    const infoBulle = (
        <span className={"info"}>
      {t(item.nom, { ns: "items" })} {typeName} (id : {item.id})
    </span>
    );
    
    if (props.onDecrementItem) {
        return (
            <div
                className={classSpecifique}
                onClick={() => props.onDecrementItem(item, broken)}
            >
                <span className={"nbrItems"}>{nbr}</span>
                <span className={classImage}>
					<span className={"infoBulle"}><SvgIcone icone={item.icon} />{infoBulle}</span>
				</span>
            </div>
        );
    } else {
        return (
            <div className={classSpecifique}>
                <span className={"nbrItems"}>{nbr}</span>
                <span className={classImage}>
					<span className={"infoBulle"}><SvgIcone icone={item.icon} />{infoBulle}</span>
				</span>
            </div>
        );
    }
}

export function BoutonCopy({ textAcopier, libelle }: {
    textAcopier: string;
    libelle?: string;
}) {
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);
    const [isError, setIsError] = useState(false);
    
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(
                textAcopier.replace(/<br\s*\/?>/gm, "\n"),
            );
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1500); // Réinitialise le libellé après 1,5 seconde
        } catch (error) {
            console.error("Erreur lors de la copie dans le presse-papiers :", error);
            setIsError(true);
        }
    };
    const boutonCopier = t("Texte copié", { ns: "ville" });
    const boutonNormal = t("Copier le texte", { ns: "ville" });
    const boutonKo = t("Appuyer sur \"Ctrl + C\" pour copier", { ns: "ville" });
    
    return (
        <button onClick={handleCopy} className={"btn btn-xs btn-primary"}>
            {isError
                ? boutonKo
                : isCopied
                    ? boutonCopier
                    : (libelle ? libelle : boutonNormal)}
        </button>
    );
}


export function handleSortData(column: string, sortedColumn: string, orderColumn: string, setSortedColumn: (column: string) => void, setOrderColumn: (column: string) => void) {
    // Si la colonne cliquée est la même que la colonne triée précédemment,
    // on inverse l'ordre de tri.
    const order = column === sortedColumn && orderColumn === "asc" ? "desc" : (orderColumn === "desc" ? "default" : "asc");
    
    if (order === "default") {
        column = "";
    }
    
    // On met à jour l'état avec la colonne actuellement triée et l'ordre de tri.
    setSortedColumn(column);
    setOrderColumn(order);
}

export function getSortIndicatorClass(column: string, sortedColumn: string, orderColumn: string) {
    if (column === sortedColumn) {
        return orderColumn === "asc" ? "asc" : "desc";
    } else {
        return "default"; // Retourne une chaîne vide si la colonne n'est pas triée
    }
}