import React               from "react";
import Form                from "react-bootstrap/Form";
import { SwitchItemProps } from "../../types/components/OptionsPerso/OptionsPersoType";


export default function SwitchItems({ label, forHtml, checked, onChangeUser, tooltip }: SwitchItemProps) {
    
    return <div className={"gestion_option_couleur_carte"}>
        {(tooltip) ? <>
            <label htmlFor={forHtml} className={"option_specifique"}>{label}</label>
            <span className={"option_specifique infoBulle"}>
                <i className="fa fa-circle-info"></i>
                <span className="option_specifique infoHelpPerso">{tooltip}</span>
            </span>
        </> : <label htmlFor={forHtml}>{label}</label>}
        <Form.Check name={forHtml} id={forHtml} type="switch" checked={checked} onChange={onChangeUser} />
    </div>;
}
