import React, { PropsWithChildren } from "react";


type ButtonProps = {
    taille: "xs" | "sm" | "md" | "lg" | "xl";
    couleur: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "link";
    onClick: () => void;
}

export default function Button(props: PropsWithChildren<ButtonProps>) {
    return <button className={`btn btn-${props.taille} btn-${props.couleur}`} onClick={() => props.onClick()}>{props.children}</button>;
}