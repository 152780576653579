import React                    from "react";
import SvgIcone                 from "../../../components/generality/SvgIcone";
import parse                    from "html-react-parser";
import { ChantierPrototypeDTO } from "../../../types/models/chantierPrototype.dto";
import { useTranslation }       from "react-i18next";

interface ListChantiersProps {
    evoChantier: ChantierPrototypeDTO[],
    translation: string[]
}

const ListEvolutions = ({ evoChantier, translation }: ListChantiersProps) => {
    const { t } = useTranslation();
    const translate: string[] = translation;
    const listEvo: ChantierPrototypeDTO[] = Object.values(evoChantier).sort((a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) => {
        const aName = t(a.nom, { ns: "chantiers" }).toLowerCase();
        const bName = t(b.nom, { ns: "chantiers" }).toLowerCase();
        return aName.localeCompare(bName);
    });
    
    return <table>
        <thead>
        <tr>
            <th className="nomEvoChantier">{t("Nom Chantier", { ns: "ency" })}</th>
            <th className="effetEvoChantier">{t("Effet de base", { ns: "ency" })}</th>
            <th className="effetEvoChantier">{t("Niveau 1", { ns: "ency" })}</th>
            <th className="effetEvoChantier">{t("Niveau 2", { ns: "ency" })}</th>
            <th className="effetEvoChantier">{t("Niveau 3", { ns: "ency" })}</th>
            <th className="effetEvoChantier">{t("Niveau 4", { ns: "ency" })}</th>
            <th className="effetEvoChantier">{t("Niveau 5", { ns: "ency" })}</th>
        </tr>
        </thead>
        <tbody>
        {listEvo.map((evo: ChantierPrototypeDTO) => {
            return <tr key={"ligne_chantier_" + evo.id}>
                <td className="nomEvoChantier" key={"ligne_evo_" + evo.id}>{t(evo.nom, { ns: "chantiers" })} <SvgIcone icone={evo.icon} /></td>
                {Object.values(evo.level_ups).map(bonus => {
                    return <td className={"effetEvoChantier"} key={"evo_chantier_" + evo.id + "_lvl_" + bonus.level}>
                        <div>
                            {Object.values(bonus.bonus_ups).map((up, index) => <span key={"evo_chantier_" + evo.id + "_lvl_" + bonus.level + "_bonus_" + index}>{parse(translate[bonus.id][up.id])}</span>)}
                        </div>
                    </td>;
                })}
            </tr>;
        })}
        
        </tbody>
    </table>;
};

export default ListEvolutions;