import { useCallback }       from "react";
import { ColorConfigExport } from "../../types/components/OptionsPerso/OptionsPersoType";

// Hook personnalisé pour mettre la première lettre en majuscule
export const useOptionPerso = () => {
    const colorWithAlpha = useCallback((couleur: string): string => {
        return (couleur.length === 7) ? couleur + "FF" : couleur;
    }, []);
    
    const controleCouleur = useCallback((couleur: string): boolean => {
        // Vérifie que la chaîne commence par "#" et fait 7 ou 9 caractères
        const hexColorPattern = /^#[0-9A-Fa-f]{6}([0-9A-Fa-f]{2})?$/;
        return hexColorPattern.test(couleur);
    }, []);
    
    const validateCouleurConfig = useCallback((config: ColorConfigExport): boolean => {
        // Récupère toutes les valeurs de l'objet et vérifie chaque couleur
        for (const key in config) {
            const value = config[key as keyof ColorConfigExport];
            if (!controleCouleur(value)) {
                console.error(`Invalid color format for ${key}: ${value}`);
                return false;
            }
        }
        return true;
    }, []);
    
    
    return { colorWithAlpha, controleCouleur, validateCouleurConfig };
};